<template>
    <tbody>
        <tr
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
            v-for="(item, index) in items"
            :key="index"
        >
            <td class="truncate">{{ item.name }}</td>
            <td class="truncate">
                {{ item.NIP }}
            </td>
            <td class="truncate">{{ item.city }}</td>
            <td class="truncate">
                {{ item.street }} {{ item.houseNumber
                }}<span v-if="item.apartmentNumber"
                    >/{{ item.apartmentNumber }}</span
                >
            </td>
            <td class="truncate">{{ item.phoneNumber }}</td>
            <td class="d-flex align-center justify-end">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-menu bottom v-if="remove()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === REMOVE === === -->
                        <v-list-item @click="openConfirmModal(item._id)">
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn small router :to="`/contacts/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {
    GlobalPermissions,
    GlobalStatus,
} from '../../../../plugins/constsTypes'
import ConfirmContent from '../../Departments/ConfirmModal/ConfirmContent.vue'
export default {
    props: ['items'],
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    components: {
        ConfirmContent,
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        ...mapActions(['deleteContact']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async deleteItem() {
            let result = await this.deleteContact({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
        remove() {
            return this.checkPermissions(GlobalPermissions.delete)
        },
    },
}
</script>
