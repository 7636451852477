<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <RegistryHeader
                    :title="getContactsRegistry.name"
                    :avatarData="getContactsRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :title="getContactsRegistry.name"
                    :items="getContactsTable.items"
                    :headers="headers"
                    :length="getContactsTable.pages"
                    :page="getContactsTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getContactsSearchFields.searchFields"
                    :loading="getContactsTable.loading"
                >
                    <Columns slot="columns" :items="getContactsTable.items" />
                </Table>
                <Modal
                    :title="$t('global:addition')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                v-if="add()"
                                small
                                @click="openModal()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('contacts:addContact') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Columns from './../../../components/Registries/Contacts/Table/Columns'
import AEContent from './../../../components/Registries/Contacts/Modal/Content'
import Buttons from './../../../components/Registries/Contacts/Modal/Buttons'
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    components: {
        Columns,
        Buttons,
        AEContent,
    },
    computed: {
        ...mapGetters([
            'getContactsTable',
            'getContactsSearchFields',
            'getContactsRegistry',
            'getSearch',
        ]),
    },

    methods: {
        ...mapActions(['fetchContactsTable', 'fetchUsers']),
        ...mapMutations([
            'setContactsTable',
            'clearContactModal',
            'clearContactsErrors',
            'setContactsSearch',
            'setDefaultFields',
            'clearContactsTable',
        ]),
        closeModal() {
            this.open = false
            this.clearContactsErrors()
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        setSearchFields(searchFields) {
            this.setContactsSearch(searchFields)
        },
        setSearch(search) {
            this.setContactsTable({ search, page: 1 })
            this.fetchContactsTable()
        },
        setPage(page) {
            console.log(page)
            this.setContactsTable({ page })
            this.fetchContactsTable()
        },
        setSort(sort) {
            this.setContactsTable({ sort })
            this.fetchContactsTable()
        },
        async openModal() {
            await void this.fetchUsers({
                select: 'name lastname mail status status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearContactModal()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearContactsTable()
            this.fetchContactsTable()
        },
    },

    data() {
        return {
            open: false,
            length: 19,
            headers: [
                {
                    // text: 'Nazwa',
                    text: this.$t('contacts:fullName'),
                    value: 'name',
                    width: '30%',
                    showSearch: true,
                },
                {
                    text: this.$t('contacts:contactId'),
                    value: 'contactId',
                    width: '0%',
                    showSearch: true,
                    isNotVisible: true,
                },
                {
                    text: this.$t('contacts:nip'),
                    value: 'NIP',
                    width: '13%',
                    showSearch: true,
                },
                {
                    text: this.$t('contacts:city'),
                    value: 'city',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('contacts:street'),
                    value: 'street',
                    width: '20%',
                    showSearch: true,
                },

                {
                    text: this.$t('contacts:phoneNumber'),
                    value: 'phoneNumber',
                    width: '100px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/contacts/:id')
            store.commit('clearContactsTable')
        store.dispatch('fetchContactsTable').then(() => next())
    },
}
</script>
